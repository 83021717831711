import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Section from "../components/Section"
import Circle from "../components/Circle"
import Figure from "../components/Figure"
import Frame from "../components/Frame"
import { H1, H2, H3, H4, Ul, Li, P, Hr } from "../components/VerticalRhythm"
import { Flex, Box } from "rebass"

import PictureSet from "../components/PictureSet"
import imgObj01 from "../imgObjs/imgObj01"

import umschlagVS from "../img/0500/Schreib-und-das-Leben-ist-leicht-UmschlagVS.jpg"

import breakpoints from "../theme/breakpoints"

const IndexPage = () => (
  <Layout>
    <SEO title="Schreib und das Leben ist leicht" keywords={[`Vera Anders`]} />
    <Figure caption="">
      <PictureSet imgObj={imgObj01} />
    </Figure>
    <Section>
      <Circle px={5.5} py={2}>
        <FontAwesomeIcon icon={["fal", "book"]} size="3x" />
      </Circle>
      <H1>Das Buch</H1>
      <Flex flexWrap="wrap">
        <Box width={[1, 1 / 2]} pr={[0, 2, 4]} mb={3}>
          <img src={umschlagVS} alt="" />
        </Box>
        <Box width={[1, 1 / 2]} pl={[0, 2, 4]}>
          <P>
            Klar und organisiert mit Stift und Tastatur: So nehmen Sie das Thema
            »Schreiben« in die Hand.
          </P>
          <P>
            »Schreib und das Leben ist leicht« — ein neues Buch für alle, die
            gern schreiben, öfter oder beruflich besser schreiben möchten.
            Kompakt und unkompliziert erklärt es die wichtigsten Methoden,
            liefert Checklisten und versorgt Sie mit einer Fülle an Ideen,
            Aufgaben und Tipps für jeden Tag.
          </P>
          <P>
            Das Buch hat 240 liebevoll gestaltete Seiten aus gutem Papier und
            eine Tasche zum Einlegen von Notizen.
          </P>
          <P>
            Viel Sorgfalt, Spaß und meine ganze Erfahrung als Texterin,
            Trainerin und Coach stecken darin. Nach über zwei Jahren Arbeit ist
            das Buch jetzt für Sie da zum Bestellen, Auspacken, Lesen und
            Hineinnotieren.
          </P>
          <P>
            Werfen Sie hier schon mal einen Blick auf den Inhalt und den{" "}
            <Link to="/blog">Blog</Link> zum Buch.
          </P>
          <P>
            Und nun viel Spaß,
            <br />
            herzlich
          </P>
          <P>
            Ihre <em>Vera Anders</em>
          </P>
        </Box>
      </Flex>
    </Section>
    <Section>
      <Hr />
      <P textAlign="center">
        <strong>INHALT</strong>
      </P>

      <Flex flexWrap="wrap" backgroundColor="white">
        <Box width={[1, 1 / 2]} pr={[0, 2, 4]} mb={2}>
          <P>
            <strong>I SCHREIB UND DAS LEBEN IST LEICHT</strong>
          </P>
          <P>
            <strong>II STARTEN</strong>
          </P>
          <ol type="1">
            <Li>Der beste Schreibplatz</Li>
            <Li mt={2}>
              <P>Einen Stil finden</P>
              <ul>
                <Li>Mit Ziel und für den Leser schreiben</Li>
                <Li>Feng-Shui gegen das Wortgerümpel</Li>
                <Li>Sätze entschachteln in drei Schritten</Li>
                <Li>Den Amtsschimmel verabschieden</Li>
              </ul>
            </Li>
            <Li mt={2}>Word-out</Li>
            <Li mt={2}>
              <P>Neue Ideen mit den sechs besten Kreativmethoden</P>
              <ul>
                <Li>Verknüpfen, was da ist: Mindmapping</Li>
                <Li>Wort für Wort locker schreiben: Clustering</Li>
                <Li>
                  Die Welt als gigantische Inspirationsquelle: der Zettelkasten
                </Li>
                <Li>Im Gedanken-Flow: die Geniale-Momente-Methode</Li>
                <Li>Auf Umwegen zur Inspiration: die Reizwortmethode</Li>
                <Li>
                  Das Sendung-mit-der-Maus-Prinzip: der Perspektivenwechsel
                </Li>
              </ul>
            </Li>
            <Li mt={2}>Word-out</Li>
            <Li mt={2}>
              <P>Sich mit kleinen Methoden das Leben erleichtern</P>
              <ul>
                <Li>Mehr als To-dos – lang lebe die Liste 60</Li>
                <Li>
                  »Alles nur in meinem Kopf?« – nicht mehr mit dem Denkzettel
                </Li>
                <Li>Alles da, wo es hingehört: Struktur-Test</Li>
                <Li>Konzentration! – die Teeuhrmethode wirkt Wunder</Li>
                <Li>Beim Kritzeln kapieren: Sketchnotes</Li>
                <Li>Das kluge Notizbuch: Journaling</Li>
                <Li>
                  Herrlich analog – Mood- und Projektboards und Kanban für Küche
                  und Büro
                </Li>
              </ul>
            </Li>
            <Li mt={2}>
              <P>Word-out</P>
            </Li>
            <Li mt={2}>
              <P>Sich selbst organisieren und mehr auf die Reihe bekommen</P>
              <ul>
                <Li>Zeit nehmen, Raum schaffen, am Ball bleiben</Li>
                <Li>Intelligent dokumentieren</Li>
                <Li>Qualität sichern – Texte korrigieren (lassen)</Li>
              </ul>
            </Li>
            <Li mt={2}>
              <P>Motivation: »Will ich wirklich? Ja!«</P>
            </Li>
            <Li mt={2}>
              <P>Word-out</P>
            </Li>
          </ol>
        </Box>
        <Box width={[1, 1 / 2]} pl={[0, 2, 4]}>
          <P>
            <strong>III SCHREIBEN FÜR ANDERE</strong>
          </P>
          <ol type="1">
            <Li>
              <P>Tag für Tag</P>
              <ul>
                <Li>Die Textkonzept-Checkliste</Li>
                <Li>
                  E-Mails und Briefe: schneller, professioneller und
                  freundlicher Texten
                </Li>
                <Li>Mit Texten verkaufen …</Li>
                <Li>… und gut dastehen</Li>
                <Li>Webtexte: Schreiben mit Profil fürs Netz</Li>
                <Li>Protokolle texten, die gelesen werden</Li>
                <Li>Berichte auf eine Seite bringen – geht immer</Li>
                <Li>Konzepte = Liebeserklärung an eine Idee</Li>
              </ul>
            </Li>
            <Li mt={2}>
              <P>Autor sein: Wie, verdammt, schreibt man einen guten Roman?</P>
            </Li>
            <Li mt={2}>
              <P>Word-out</P>
            </Li>
          </ol>
          <P>
            <strong>IV SCHREIBEN FÜR SICH</strong>
          </P>
          <ol type="1">
            <Li>
              <P>Faszinierende Selbstversuche</P>
              <ul>
                <Li>Mission gute Laune – Wörter gegen hängende Mundwinkel</Li>
                <Li>»Trink ein Glas Wein, aber faste Wörter.«</Li>
                <Li>Leichter durch Schreiben</Li>
                <Li>Das »Wie will ich leben?«-Projekt</Li>
              </ul>
            </Li>
            <Li mt={2}>
              <P>Inspirierende Log- und Tagebücher</P>
              <ul>
                <Li>Zwei Sätze am Tag: das Glückstagebuch</Li>
                <Li>Sagt mehr als 1.000 Urlaubsbilder: das Reisetagebuch</Li>
                <Li>Nächtliches Doppelleben – aufgedeckt im Traumtagebuch</Li>
              </ul>
            </Li>
            <Li mt={2}>
              <P>Erkenntnisse durch Schreiben</P>
              <ul>
                <Li>Ziele finden, setzen und erreichen</Li>
                <Li>
                  Fünf Mal »Warum?« fragen und auf einmal viel mehr verstehen
                </Li>
                <Li>Glück und Sinn finden mit den fünf Elementarfragen</Li>
              </ul>
            </Li>
            <Li mt={2}>
              <P>Word-out</P>
            </Li>
          </ol>
          <P>
            <strong>V SCHLUSSWÖRTER</strong>
          </P>
          <ul>
            <Li>
              <P>Eine Liste der Werte, Motivatoren, Ideale und Maßstäbe</P>
            </Li>
            <Li>
              <P>Eine Liste der Gefühle und Zustände</P>
            </Li>
          </ul>
        </Box>
      </Flex>
    </Section>
  </Layout>
)

export default IndexPage
